import React from 'react';
import {Link} from 'gatsby'
import {
  Viewer,
  UrlTemplateImageryProvider,
  Credit,
  // KmlDataSource,
  HeadingPitchRange, 
  // JulianDate,
  Rectangle,
  // Sandcastle, 
  // Clock, 
  // ClockRange, 
  // ClockStep, 
  ClockViewModel, 
  ImagerySplitDirection, 
  ScreenSpaceEventHandler,
  ScreenSpaceEventType, 
  // Cartesian2, 
  Cartesian3, 
  Transforms, 
  // Camera, 
  SceneMode, 
  // defined, 
  // WorldPosition, 
  Math, 
  Color
 } from '../../../node_modules/cesium';
import '../../../node_modules/cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";
// import { backgroundColor } from 'styled-system';
// import Styles from "./cesium.module.css";
import { buttonshitakml, buttononoffkml, canvaswrap, slider, buttonshita, radiobutton, buttononoff, dupoya, view3D, view2D } from "./cesium.module.css";
import Cityset1 from "../citysetting/citytitle7";

import { Entity, 
  // ShadowMode
 } from '../../../node_modules/cesium';

class CesiumContainer extends React.Component {
  divRef = React.createRef();
  viewer = null;
  entity = null;

  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div>

<div style={{display:"flex"}}>
<div style={{width:"70%"}}>
    <Cityset1
      kml_kmz={this.props.kml_kmz}
     />
  </div>
{/*  */}
<div className={buttonshitakml}>
            <div className={buttononoffkml} type="button" id="kmldownloadbutton2" value="GoogleEarthで表示">GoogleEarthで表示</div>
          </div>
          </div>
{/*  */}



        <div className={canvaswrap} >
          <div id="cesiumContainerdiv" className={dupoya} >
            <div id="view3D" className={view3D} style={{height:this.props.height}}>
              <div id="slider" className={slider} style={{height:this.props.height}}></div>
            </div>
            <div id="view2D" className={view2D} style={{height:this.props.height}}>
            </div>
          </div>
        </div>

        <div style={{position:"relative"}}>




      <div style={{width:"50%"}}>
        <div style={{display:"flex", width:"100%"}}>
          {/* <div className={buttonyear3rendou}>
            OFF
          </div> */}
        </div>
      </div>


{/* 
<div style={{position:"absolute",width:"100%",backgroundColor:"gray",margin:"-5vh 0 0 0", height:"10vh",zIndex:0}}>bbb</div> */}



      {/* <div style={{width:"50%"}}>
        <div style={{display:"flex",justifyContent: "space-between"}}> */}

          {/* <div className={buttonshita}>
            <input className={buttononoff} type="button" id="rotate" value="Rotate"/>
          </div> */}

          {/* <div className={buttonshita}>
            <Link
              className={buttononoff}
              to={this.props.full_url}
              // to={"/"+this.props.no+"/kokudof"}
              kml_kml={this.props.kml_kml}
              kml_kmz={this.props.kml_kmz}
              // dir="/kml/sample.kml"
              width="100%"
              height="700px"
              no={this.props.no}
            >
            {this.props.full_name}
            </Link>
          </div> */}

        {/* </div>
      </div> */}



<div style={{marginTop:"70vh",display:"flex",justifyContent: "space-between"}}>





<div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton1" id="3Dtimeset1" value={this.props.year1}/>
            <label htmlFor="3Dtimeset1">{this.props.year1}</label>
          </div>

          <div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton1" id="3Dtimeset2" value={this.props.year2}/>
            <label htmlFor="3Dtimeset2">{this.props.year2}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton1" id="3Dtimeset3" value={this.props.year3}/>
            <label htmlFor="3Dtimeset3">{this.props.year3}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton1" id="3Dtimeset4" value={this.props.year4}/>
            <label htmlFor="3Dtimeset4">{this.props.year4}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton1" id="3Dtimeset5" value={this.props.year5}/>
            <label htmlFor="3Dtimeset5">{this.props.year5}</label>
          </div>




{/* 
<div className={buttonshita}>
  <input className={buttononoff} type="button" id="3Dtimeset1" value={this.props.year1}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="3Dtimeset2" value={this.props.year2}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="3Dtimeset3" value={this.props.year3}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="3Dtimeset4" value={this.props.year4}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="3Dtimeset5" value={this.props.year5}/>
</div> */}





<div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton2" id="2Dtimeset1" value={this.props.year1}/>
            <label htmlFor="2Dtimeset1">{this.props.year1}</label>
          </div>

          <div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton2" id="2Dtimeset2" value={this.props.year2}/>
            <label htmlFor="2Dtimeset2">{this.props.year2}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton2" id="2Dtimeset3" value={this.props.year3}/>
            <label htmlFor="2Dtimeset3">{this.props.year3}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton2" id="2Dtimeset4" value={this.props.year4}/>
            <label htmlFor="2Dtimeset4">{this.props.year4}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton2" id="2Dtimeset5" value={this.props.year5}/>
            <label htmlFor="2Dtimeset5">{this.props.year5}</label>
          </div>


{/* 
<div className={buttonshita}>
  <input className={buttononoff} type="button" id="2Dtimeset1" value={this.props.year1}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="2Dtimeset2" value={this.props.year2}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="2Dtimeset3" value={this.props.year3}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="2Dtimeset4" value={this.props.year4}/>
</div>

<div className={buttonshita}>
  <input className={buttononoff} type="button" id="2Dtimeset5" value={this.props.year5}/>
</div>
 */}

</div>
</div>


<div style={{display:"flex",justifyContent: "space-between"}}>

  <div className={buttonshita}>
    <input className={radiobutton} type="radio" name="yearbutton1" id="3Dtimeoff" value="背景だけを表示"/>
    <label htmlFor="3Dtimeoff">（左）背景だけを表示</label>
  </div>

  <div className={buttonshita}>
    <div className={buttononoff} type="button" id="originview" value="はじめに戻る">はじめに戻る</div>
  </div>






















{/* 

いったん消去、調整後復活させる
下のscriptも合わせて復活のこと（回転スクリプトと戻すスクリプトの両方を）
*/}

<div className={buttonshita}>
<div className={buttononoff} type="button" id="rotate" value="回転させる">回転させる</div>
</div>


























<div className={buttonshita}>
  <Link
    className={buttononoff}
    to={this.props.full_url}
    // to={"/"+this.props.no+"/kokudof"}
    kml_kml={this.props.kml_kml}
    kml_kmz={this.props.kml_kmz}
    // dir="/kml/sample.kml"
    width="100%"
    height="70vh"
    no={this.props.no}
  >
  {this.props.full_name}
  </Link>
</div>

  <div className={buttonshita}>
    <div type="button" id="googlemapopen"  className={buttononoff} value="場所を確認する">場所を確認する</div>
  </div>

  <div className={buttonshita}>
    <input className={radiobutton} type="radio" name="yearbutton2" id="2Dtimeoff" value="背景だけを表示"/>
    <label htmlFor="2Dtimeoff">（右）背景だけを表示</label>
  </div>

{/* 
<div className={buttonshita}>
  <div className={buttononoff} type="button" id="timesetoff" value="背景だけを表示">背景だけを表示</div>
</div> */}

</div>
</div>

    );
  }


  componentDidMount() {

    // var clock = new Clock({
    //     startTime : JulianDate.fromIso8601('2010-08-30T11:56:04+08'),
    //     stopTime : JulianDate.fromIso8601('2025-08-30T11:56:04+08'),
    //     currentTime : JulianDate.fromIso8601('2017-12-30T11:56:04+08'),
    //     clockRange : ClockRange.LOOP_STOP,
    //     clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    // });

    // var extent = Rectangle.fromDegrees(122, 20, 153, 45);
    // Camera.DEFAULT_VIEW_RECTANGLE = extent;
    // Camera.DEFAULT_VIEW_FACTOR = 0;

var clockViewModel3D = new ClockViewModel();
var clockViewModel2D = new ClockViewModel();
var options3D = {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  baseLayerPicker: false,
  timeline : false,
  animation : false,
  fullscreenButton: false,
  homeButton: false,
  vrButton: false,
  geocoder:false,
  sceneModePicker:false,
  navigationHelpButton:false,
  infoBox : false,
  selectionIndicator : false,
  shadows : false,
  clockViewModel: clockViewModel3D,
};
var options2D = {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  homeButton: false,
  fullscreenButton: false,
  sceneModePicker: false,
  clockViewModel: clockViewModel2D,
  infoBox: false,
  geocoder: false,
  // sceneMode: SceneMode.SCENE2D,
  navigationHelpButton: false,
  animation: false,
  timeline: false,
};


/// ビューア起動(左) ///////
var view3D = new Viewer("view3D", options3D);
/// ビューア起動(右) ///////
var view2D = new Viewer("view2D", options2D);
/////// ビューア起動 ///

var masterCamera = view3D.scene.camera;
var slaveCamera = view2D.scene.camera;
var rendouonoff = true;

view2D.scene.preRender.addEventListener(function(){
  if(rendouonoff == true){
    if(view2D.scene.mode !== SceneMode.MORPHING){
      slaveCamera.setView({
          destination : masterCamera.position,
          orientation : {
          heading : masterCamera.heading,
          pitch : masterCamera.pitch ,
          roll :  masterCamera.roll
      }
      });
    }
  }
});




















// /// KMLデータ準備 ///////
//     var options3D = {
//       camera: view3D.scene.camera,
//       canvas: view3D.scene.canvas,
//     };
//     var options2D = {
//       camera: view2D.scene.camera,
//       canvas: view2D.scene.canvas,
//     };
//     var dataSource3D = new KmlDataSource.load(this.props.kml_kml,options3D);
//     var dataSource2D = new KmlDataSource.load(this.props.kml_kml,options2D);
// /////// KMLデータ準備 ///

// /// KMLデータ読み込み ///////
// view2D.dataSources.add(dataSource2D).show = true;
// view3D.dataSources.add(dataSource3D).show = true;
// view3D.zoomTo(dataSource3D
//   // , new HeadingPitchRange(0, -1, 2000000)
//   );
//   // view3D.dataSources.add(KmlDataSource.load(urlaaa,options3D));
// /////// KMLデータ読み込み ///












/// CSVデータ読み込み ///////
var data1995 = view3D.entities.add(new Entity());
var data2000 = view3D.entities.add(new Entity());
var data2005 = view3D.entities.add(new Entity());
var data2010 = view3D.entities.add(new Entity());
var data2015 = view3D.entities.add(new Entity());

var data1995_2 = view2D.entities.add(new Entity());
var data2000_2 = view2D.entities.add(new Entity());
var data2005_2 = view2D.entities.add(new Entity());
var data2010_2 = view2D.entities.add(new Entity());
var data2015_2 = view2D.entities.add(new Entity());

var min = this.props.min;
var ratioa = this.props.ratio;
var shikii1a = this.props.shikii1;
var shikii2a = this.props.shikii2;
var shikii3a = this.props.shikii3;
var hanrei1_color = this.props.hanrei1_color;
var hanrei2_color = this.props.hanrei2_color;
var hanrei3_color = this.props.hanrei3_color;
var hanrei4_color = this.props.hanrei4_color;
var csv = this.props.csv
var csvmode = this.props.csvmode

var ratio = Number(ratioa);
var shikii1 = Number(shikii1a);
var shikii2 = Number(shikii2a);
var shikii3 = Number(shikii3a);


/// /// １.2列目の閾値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
function mesh3rdRect1( meshcode3rd , h, c){
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0,2));
  var u = parseInt(strMeshcoe.slice(2,4));
  var q = parseInt(strMeshcoe.slice(4,5));
  var v = parseInt(strMeshcoe.slice(5,6));
  var r = parseInt(strMeshcoe.slice(6,7));
  var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12 + r / 120;
  var lon = u + 100 + v * 0.125 + w * 0.0125;

    var color1;
    if (h >= shikii1) {
      color1 = hanrei1_color;
      // color1 = "#67ADDF";
    } else if (h >= shikii2) {
      color1 = hanrei2_color;
    } else if (h >= shikii3) {
      color1 = hanrei3_color;
    } else {
      color1 = hanrei4_color;
    }

  var ans_entities = {
          // parent: data1995,
          // id:meshcode3rd ,
          // description : "<p>"+description_text+"</p>",
          // rectangle : {
              coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
              height : 0,
              extrudedHeight : h*ratio,
              material : Color.fromCssColorString(color1),
              // material : Color.fromRgba( color_gbra )
              outline : true,
              outlineColor : "white",
              outlineWidth : 1,
              // shadows : ShadowMode.ENABLED
          // }
      };
  return ans_entities ;
}
/// ///////////////////////// １.2列目の閾値で色分け ///
/// /// 2.3列目の値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
  function mesh3rdRect2( meshcode3rd , h, c){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    var r = parseInt(strMeshcoe.slice(6,7));
    var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12 + r / 120;
    var lon = u + 100 + v * 0.125 + w * 0.0125;
  
      var color1;
      if (c == 3) {
        color1 = hanrei1_color;
        // color1 = "#67ADDF";
      } else if (c == 2) {
        color1 = hanrei2_color;
      } else if (c == 1) {
        color1 = hanrei3_color;
      } else {
        color1 = hanrei4_color;
      }
  
    var ans_entities = {
            // parent: data1995,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            // rectangle : {
                coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
                height : 0,
                extrudedHeight : h*ratio,
                material : Color.fromCssColorString(color1),
                // material : Color.fromRgba( color_gbra )
                outline : true,
                outlineColor : "white",
                outlineWidth : 1,
                // shadows : ShadowMode.ENABLED
            // }
        };
    return ans_entities ;
  }

/// ///////////////////////// 2.3列目の値で色分け ///

/// /// 3.2列目の閾値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
  function mesh3rdRect3( meshcode3rd , h, c){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    var r = parseInt(strMeshcoe.slice(6,7));
    var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12 + r / 120;
    var lon = u + 100 + v * 0.125 + w * 0.0125;
  

      var color1;
      if (c >= shikii1) {
        color1 = hanrei1_color;
        // color1 = "#67ADDF";
      } else if (c >= shikii2) {
        color1 = hanrei2_color;
      } else if (c >= shikii3) {
        color1 = hanrei3_color;
      } else {
        color1 = hanrei4_color;
      }
  
    var ans_entities = {
            // parent: data1995,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            // rectangle : {
                coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
                height : 0,
                extrudedHeight : h*ratio,
                material : Color.fromCssColorString(color1),
                // material : Color.fromRgba( color_gbra )
                outline : true,
                outlineColor : "white",
                outlineWidth : 1,
                // shadows : ShadowMode.ENABLED
            // }
        };
    return ans_entities ;
  }
  /// ///////////////////////// 3.2列目の閾値で色分け ///




function getCSV(no,year,dataname) {
  var req = new XMLHttpRequest();
  req.open('get', csv+"_"+year+".csv", true);
  req.send(null);
  req.onload = function() {
    // setCSV(req.responseText);
    //ここでlocalStorageへ
    var dataArr;
    // var r = document.getElementById('r');
    var tmp = req.responseText.split('\n');
    // console.log(tmp.length);

    // var firstview = view3D.entities.add(mesh3rdRect1(tmp[0].substr(0,6),tmp[0].substr(7))).then(
    //   view3D.flyTo(view3D.entities)
    // )
    // .catch(failureCallback);

    if (csvmode == "color_shikii") {

    for(let i = 0, len = tmp.length; i< len ; i++){
      dataArr = tmp[i].split(',');
      var minmin = Number(min);
      if (dataArr[1]>minmin) {
      view3D.entities.add(
          {
            parent: dataname,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            rectangle: mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2])
          }
        );
      // view3D.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
      // if(tmp[0]){
      // }
      }
    }

  } else if(shikii1 !== 0){

      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          view3D.entities.add(
            {
              parent: dataname,
              // id:meshcode3rd ,
              // description : "<p>"+description_text+"</p>",
              rectangle: mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2])
            }
          );
        // view3D.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
  
  

  } else {

    for(let i = 0, len = tmp.length; i< len ; i++){
      dataArr = tmp[i].split(',');
      var minmin = Number(min);
      if (dataArr[1]>minmin) {
        view3D.entities.add(
          {
            parent: dataname,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            rectangle: mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2])
          }
        );
      // view3D.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
      // if(tmp[0]){
      // }
      }
    }

  }





    view3D.zoomTo(view3D.entities);
    
        // tmp.forEach(x => {
        //   dataArr = x.split(',');
        //   if (dataArr[1]>10000) {
        //     view3D.entities.add(mesh3rdRect1(dataArr[0],dataArr[1]));
        //   }
        // });
    
  };
}



function getCSV2(no,year,dataname) {
  var req = new XMLHttpRequest();
  req.open('get', csv+"_"+year+".csv", true);
  req.send(null);
  req.onload = function() {
    // setCSV(req.responseText);
    //ここでlocalStorageへ
    var dataArr;
    // var r = document.getElementById('r');
    var tmp = req.responseText.split('\n');
    // console.log(tmp.length);

    // var firstview = view3D.entities.add(mesh3rdRect1(tmp[0].substr(0,6),tmp[0].substr(7))).then(
    //   view3D.flyTo(view3D.entities)
    // )
    // .catch(failureCallback);

    if (csvmode == "color_shikii") {

    for(let i = 0, len = tmp.length; i< len ; i++){
      dataArr = tmp[i].split(',');
      var minmin = Number(min);
      if (dataArr[1]>minmin) {
        view2D.entities.add(
          {
            parent: dataname,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            rectangle: mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2])
          }
        );
      // view3D.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
      // if(tmp[0]){
      // }
      }
    }

  } else if(shikii1 !== 0){

      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          view2D.entities.add(
            {
              parent: dataname,
              // id:meshcode3rd ,
              // description : "<p>"+description_text+"</p>",
              rectangle: mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2])
            }
          );
        // view3D.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
  
  

  } else {

    for(let i = 0, len = tmp.length; i< len ; i++){
      dataArr = tmp[i].split(',');
      var minmin = Number(min);
      if (dataArr[1]>minmin) {
      view2D.entities.add(
          {
            parent: dataname,
            // id:meshcode3rd ,
            // description : "<p>"+description_text+"</p>",
            rectangle: mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2])
          }
        );
      // view3D.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
      // if(tmp[0]){
      // }
      }
    }

  }





    // view2D.zoomTo(view2D.entities);
    
    
    
    
    
    // tmp.forEach(x => {
        //   dataArr = x.split(',');
        //   if (dataArr[1]>10000) {
        //     view3D.entities.add(mesh3rdRect1(dataArr[0],dataArr[1]));
        //   }
        // });
    
  };
}



getCSV(this.props.no,this.props.year1, data1995);
getCSV(this.props.no,this.props.year2, data2000);
getCSV(this.props.no,this.props.year3, data2005);
getCSV(this.props.no,this.props.year4, data2010);
getCSV(this.props.no,this.props.year5, data2015);

getCSV2(this.props.no,this.props.year1, data1995_2);
getCSV2(this.props.no,this.props.year2, data2000_2);
getCSV2(this.props.no,this.props.year3, data2005_2);
getCSV2(this.props.no,this.props.year4, data2010_2);
getCSV2(this.props.no,this.props.year5, data2015_2);
// getCSV(101,2015, data2015);
data1995.show = false;
data2000.show = false;
data2005.show = false;
data2010.show = false;
data2015.show = true;

data1995_2.show = true;
data2000_2.show = false;
data2005_2.show = false;
data2010_2.show = false;
data2015_2.show = false;



document.getElementById('3Dtimeset5').checked = true;
document.getElementById('2Dtimeset1').checked = true;
// document.getElementById('2Dtimeset5').checked = true;












// let meshdata = [
//   [50302089, 10000],
//   [50302078, 5000],
//   [50302066, 1000],
//  ]
 
// console.log(meshdata[0]);

// var meshmesh = getJapan3rdmeshRectangle(meshdata[0][0],meshdata[0][1]);
// var meshmesh2 = getJapan3rdmeshRectangle( 50302078 , 5000);
// var meshmesh3 = getJapan3rdmeshRectangle( 50302066 , 5000);

// var wyoming = view3D.entities.add(meshmesh);
// var wyoming = view3D.entities.add(meshmesh2);
// var wyoming = view3D.entities.add(meshmesh3);
// view3D.zoomTo(wyoming);

/////// CSVデータ読み込み ///






























// view3D.scene.preRender.addEventListener(function(){
//     if(view3D.scene.mode !== SceneMode.MORPHING){
//       masterCamera.setView({
//           destination : slaveCamera.position,
//           orientation : {
//           heading : slaveCamera.heading,
//           pitch : slaveCamera.pitch,
//           roll :  slaveCamera.roll
//       }
//       });
//     }
// });



/// セシウムのロゴを消す ///////
    view3D._cesiumWidget._creditContainer.style.display = "none";
    view2D._cesiumWidget._creditContainer.style.display = "none";
/////// セシウムのロゴを消す ///

// /// スライダー3D //////////
// var layers2 = view3D.imageryLayers;
// var chiriinMap2 = layers2.addImageryProvider(
//   new UrlTemplateImageryProvider({
//     // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
//     url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
// //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
//     credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
//   }),
// );
// chiriinMap2.splitDirection = ImagerySplitDirection.LEFT;


// var slider2 = document.getElementById("slider2");
// view3D.scene.imagerySplitPosition = slider2.offsetLeft / slider2.parentElement.offsetWidth;
// var handler2 = new ScreenSpaceEventHandler(slider2);
// var moveActive2 = false;
// function move(movement){
//   if(!moveActive2){
//     return;
//   }
// var relativeOffset2 = movement.endPosition.x;
// var splitPosition2 = (slider2.offsetLeft + relativeOffset2) / slider2.parentElement.offsetWidth;
// slider2.style.left = 50.0 * splitPosition2 + "%";



// view3D.scene.imagerySplitPosition = splitPosition2;


// }
// handler2.setInputAction(function () {
//   moveActive2 = true;
// }, ScreenSpaceEventType.LEFT_DOWN);
// handler2.setInputAction(function () {
//   moveActive2 = true;
// }, ScreenSpaceEventType.PINCH_START);
// handler2.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
// handler2.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
// handler2.setInputAction(function () {
//   moveActive2 = false;
// }, ScreenSpaceEventType.LEFT_UP);
// handler2.setInputAction(function () {
//   moveActive2 = false;
// }, ScreenSpaceEventType.PINCH_END);
// //////// スライダー ///


/// スライダー3D //////////
var layers = view3D.imageryLayers;
var chiriinMap = layers.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap.splitDirection = ImagerySplitDirection.LEFT;

var slider = document.getElementById("slider");
view3D.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;


var layers2D = view2D.imageryLayers;
var chiriinMap2D = layers2D.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap2D.splitDirection = ImagerySplitDirection.LEFT;
view2D.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;



var handler = new ScreenSpaceEventHandler(slider);
var moveActive = false;

function move(movement){
  if(!moveActive){
    return;
  }
var relativeOffset = movement.endPosition.x;
var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
slider.style.left = 50.0 * splitPosition + "%";
view3D.scene.imagerySplitPosition = splitPosition ;
view2D.scene.imagerySplitPosition = splitPosition ;
}

handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.LEFT_DOWN);
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.PINCH_START);
handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.LEFT_UP);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.PINCH_END);
//////// スライダー ///



// /// スライダー2D //////////
// var layers = view3D.imageryLayers;
// var chiriinMap = layers.addImageryProvider(
//   new UrlTemplateImageryProvider({
//     // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
//     url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
// //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
//     credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
//   }),
// );
// chiriinMap.splitDirection = ImagerySplitDirection.LEFT;


// var slider = document.getElementById("slider");
// view3D.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;
// var handler = new ScreenSpaceEventHandler(slider);
// var moveActive = false;
// function move(movement){
//   if(!moveActive){
//     return;
//   }
// var relativeOffset = movement.endPosition.x;
// var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
// slider.style.left = 50.0 * splitPosition + "%";
// view3D.scene.imagerySplitPosition = splitPosition;
// }

// handler.setInputAction(function () {
//   moveActive = true;
// }, ScreenSpaceEventType.LEFT_DOWN);
// handler.setInputAction(function () {
//   moveActive = true;
// }, ScreenSpaceEventType.PINCH_START);
// handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
// handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
// handler.setInputAction(function () {
//   moveActive = false;
// }, ScreenSpaceEventType.LEFT_UP);
// handler.setInputAction(function () {
//   moveActive = false;
// }, ScreenSpaceEventType.PINCH_END);
// //////// スライダー ///

// /// GoogleMapを開く //////////
var buttongoogle = document.getElementById('googlemapopen');
buttongoogle.addEventListener('click', function() {
  var cartographic = view3D.camera.positionCartographic;
  // console.log(cartographic);
          // var win;
          var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
          var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
          var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
          window.open(url ,"gmap" ,'width=800,height=600');
}, false);
// ////////// GoogleMapを開く ///
























// いったん消去、復活させる
// 視点を戻すボタンの中の、回転ストップも、忘れずに復活させる

/// 回転ボタン //////////
// document.getElementById('rotate').innerHTML =
// '<div className={buttononoff} type="button" id="rotate" value="回転させる">回転させる</div>';
var buttona = document.getElementById('rotate');
buttona.addEventListener('click', function() {
    let currentvalue = document.getElementById('rotate').value;
    if (currentvalue === "回転させる") {
        document.getElementById("rotate").value="Stop"
          view3D.clock.onTick.addEventListener(function(clock) {
          view3D.scene.camera.rotateRight(-0.005);
          // rendouonoff = true;
        });
          view2D.clock.onTick.addEventListener(function(clock) {
          view2D.scene.camera.rotateRight(-0.005);
        });
    } else {
          document.getElementById("rotate").value="回転させる";
          rendouonoff = false;
          view3D.zoomTo(view3D.entities).then(
              function(){
              var cartographic3D = view3D.camera.positionCartographic;
              var cartographic2D = view3D.camera.positionCartographic;

              var center3D = Cartesian3.fromRadians(cartographic3D.longitude, cartographic3D.latitude+cartographic3D.height*1.52849689e-7, 100);
              //latitude > +0.003
              var transform3D = Transforms.eastNorthUpToFixedFrame(center3D);
              
              var center2D = Cartesian3.fromRadians(cartographic2D.longitude, cartographic2D.latitude+cartographic2D.height*1.52849689e-7, 100);
              var transform2D = Transforms.eastNorthUpToFixedFrame(center2D);

              view3D.scene.camera.lookAtTransform(transform3D, new HeadingPitchRange(0, -0.5, cartographic3D.height*1.5));
              view2D.scene.camera.lookAtTransform(transform2D, new HeadingPitchRange(0, -0.5, cartographic2D.height*1.5));
              view3D.clock.onTick.addEventListener(function(clock) {
                view3D.scene.camera.rotateRight(0.005);
              });
              view2D.clock.onTick.addEventListener(function(clock) {
                view2D.scene.camera.rotateRight(0.005);
              });
        }
      )
      ;
          // view2D.zoomTo(dataSource2D).then(
          //     function(){
          //     var cartographic = view2D.camera.positionCartographic;
          //     console.log(cartographic);
          //     var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 100);
          //     console.log(cartographic.latitude);
          //     //latitude > +0.003
          //     var transform = Transforms.eastNorthUpToFixedFrame(center);
          //     view2D.scene.camera.lookAtTransform(transform, new HeadingPitchRange(0, -0.5, cartographic.height*1.5));
          //     view2D.clock.onTick.addEventListener(function(clock) {
          //       view2D.scene.camera.rotateRight(0.005);
          //     });
          //     }
          // )
          // ;
    }
}, false);
////////// 回転ボタン ///
























/// 回転ボタン //////////
// document.getElementById('rotate').innerHTML =
// // '<div className={buttononoff} type="button" id="rotate" value="Pin">Pin</div>';
// var buttona = document.getElementById('rotate');
// buttona.addEventListener('click', function() {
//     let currentvalue = document.getElementById('rotate').value;
//     if (currentvalue == "Pin") {
//         document.getElementById("rotate").value="Stop"
//         view3D.clock.onTick.addEventListener(function(clock) {
//           view3D.scene.camera.rotateRight(-0.005);
//         });
//     } else {
//           document.getElementById("rotate").value="Pin"
//           view3D.zoomTo(dataSource3D).then(
//               function(){
//               var cartographic = view3D.camera.positionCartographic;
//               console.log(cartographic);
//               var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 100);
//               console.log(cartographic.latitude);
//               //latitude > +0.003
//               var transform = Transforms.eastNorthUpToFixedFrame(center);
//               view3D.scene.camera.lookAtTransform(transform, new HeadingPitchRange(0, -0.5, cartographic.height*1.5));
//               view3D.clock.onTick.addEventListener(function(clock) {
//                 view3D.scene.camera.rotateRight(0.005);
//               });
//               });
//     }
// }, false);
////////// 回転ボタン ///



/// KMLのON/OFFボタン //////////
    // document.getElementById('toolbar2').innerHTML =
    // '<div className={buttononoff}><input type="button" id="onoff" value="Off"/></div>';

    // var buttona = document.getElementById('onoff');
    // buttona.addEventListener('click', function() {
    //     let currentvalue = document.getElementById('onoff').value;
    //     // buttona state changed.
    //     if (currentvalue == "On") {
    //         // Show if not shown.
    //         document.getElementById("onoff").value="Off"
    //         if (!view3D.dataSources.contains(dataSource1)) {
    //             view3D.dataSources.add(dataSource1).show = true;
    //             // view3D.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    //         }
    //     } else {
    //         // Hide if currently shown.
    //         // if (view3D.dataSources.contains(dataSource1)) {
    //           document.getElementById("onoff").value="On"
    //           view3D.dataSources.removeAll();
    //           // view3D.dataSources.remove(dataSource1);
    //         // }
    //     }
    // }, false);
////////// KMLのON/OFFボタン ///

// /// 視点を戻すボタン（PINも解除） //////////
//     var buttona = document.getElementById('originview');
//     buttona.addEventListener('click', function() {
//                 view3D.flyTo(dataSource3D, new HeadingPitchRange(0, -1, 50000));
//     }, false);
// ////////// 視点を戻すボタン（PINも解除） ///
/// 視点を戻すボタン（PINも解除） //////////
var buttonb = document.getElementById('originview');
buttonb.addEventListener('click', function() {
            // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));





















            // rotateを復活させたらここも忘れずに戻す
            let currentvalue = document.getElementById('rotate').value;
            if (currentvalue === "回転させる") {
                document.getElementById("rotate").value="Stop";
                view3D.clock.onTick.addEventListener(function(clock) {
                  view3D.scene.camera.rotateRight(-0.005);
                });
                view2D.clock.onTick.addEventListener(function(clock) {
                  view2D.scene.camera.rotateRight(-0.005);
                });
              };
              
            rendouonoff = true;





















            view3D.flyTo(view3D.entities, new HeadingPitchRange(0, -1, 50000));

          }, false);
////////// 視点を戻すボタン（PINも解除） ///






/// 時間セットボタン1 //////////
var buttontimeset1 = document.getElementById('3Dtimeset1');
buttontimeset1.addEventListener('change', function() {
if(buttontimeset1.checked){
  data2000.show = false;
  data2005.show = false;
  data2010.show = false;
  data2015.show = false;
  data1995.show = true;
}
}, false);
////////// 時間セットボタン1 ///
// /// 時間セットボタン2 //////////
var buttontimeset2 = document.getElementById('3Dtimeset2');
buttontimeset2.addEventListener('change', function() {
if(buttontimeset2.checked){
data1995.show = false;
data2005.show = false;
data2010.show = false;
data2015.show = false;
data2000.show = true;
};
}, false);
// ////////// 時間セットボタン2 ///
// /// 時間セットボタン3 //////////
var buttontimeset3 = document.getElementById('3Dtimeset3');
buttontimeset3.addEventListener('change', function() {
  if(buttontimeset3.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2015.show = false;
    data2005.show = true;
  };
}, false);
// ////////// 時間セットボタン3 ///
// /// 時間セットボタン4 //////////
var buttontimeset4 = document.getElementById('3Dtimeset4');
buttontimeset4.addEventListener('change', function() {
  if(buttontimeset4.checked){
    data1995.show = false;
    data2000.show = false;
    data2015.show = false;
    data2005.show = false;
    data2010.show = true;
  };
}, false);
// ////////// 時間セットボタン4 ///
// /// 時間セットボタン5 //////////
var buttontimeset5 = document.getElementById('3Dtimeset5');
buttontimeset5.addEventListener('change', function() {
  if(buttontimeset5.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2005.show = false;
    data2015.show = true;
  };
}, false);
// ////////// 時間セットボタン5 ///
// /// 時間セットボタンoff //////////
var buttontimesetoff = document.getElementById('3Dtimeoff');
buttontimesetoff.addEventListener('change', function() {
  if(buttontimesetoff.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2005.show = false;
    data2015.show = false;
  };
}, false);
// ////////// 時間セットボタンoff ///



/// 時間セットボタン1 //////////
var buttontimeset1_2 = document.getElementById('2Dtimeset1');
buttontimeset1_2.addEventListener('change', function() {
if(buttontimeset1_2.checked){
  data2000_2.show = false;
  data2005_2.show = false;
  data2010_2.show = false;
  data2015_2.show = false;
  data1995_2.show = true;
}
}, false);
////////// 時間セットボタン1 ///
// /// 時間セットボタン2 //////////
var buttontimeset2_2 = document.getElementById('2Dtimeset2');
buttontimeset2_2.addEventListener('change', function() {
if(buttontimeset2_2.checked){
data1995_2.show = false;
data2005_2.show = false;
data2010_2.show = false;
data2015_2.show = false;
data2000_2.show = true;
};
}, false);
// ////////// 時間セットボタン2 ///
// /// 時間セットボタン3 //////////
var buttontimeset3_2 = document.getElementById('2Dtimeset3');
buttontimeset3_2.addEventListener('change', function() {
if(buttontimeset3_2.checked){
data1995_2.show = false;
data2000_2.show = false;
data2010_2.show = false;
data2015_2.show = false;
data2005_2.show = true;
};
}, false);
// ////////// 時間セットボタン3 ///
// /// 時間セットボタン4 //////////
var buttontimeset4_2 = document.getElementById('2Dtimeset4');
buttontimeset4_2.addEventListener('change', function() {
if(buttontimeset4_2.checked){
data1995_2.show = false;
data2000_2.show = false;
data2015_2.show = false;
data2005_2.show = false;
data2010_2.show = true;
};
}, false);
// ////////// 時間セットボタン4 ///
// /// 時間セットボタン5 //////////
var buttontimeset5_2 = document.getElementById('2Dtimeset5');
buttontimeset5_2.addEventListener('change', function() {
if(buttontimeset5_2.checked){
data1995_2.show = false;
data2000_2.show = false;
data2010_2.show = false;
data2005_2.show = false;
data2015_2.show = true;
};
}, false);
// ////////// 時間セットボタン5 ///


// /// 時間セットボタンoff //////////
var buttontimesetoff_2 = document.getElementById('2Dtimeoff');
buttontimesetoff_2.addEventListener('change', function() {
  if(buttontimesetoff_2.checked){
    data1995_2.show = false;
    data2000_2.show = false;
    data2010_2.show = false;
    data2005_2.show = false;
    data2015_2.show = false;
      };
}, false);
// ////////// 時間セットボタンoff ///







// /// 2D時間セットボタン1 //////////
// var yearset1 = this.props.year1+"-11-30";
// var button2Dtimeset1 = document.getElementById('2Dtimeset1');
// button2Dtimeset1.addEventListener('click', function() {
//   view2D.clock.currentTime =  JulianDate.fromIso8601(yearset1);
//   view2D.clock.multiplier = 1;
//   view2D.clock.shouldAnimate = true; //if it was paused.
// }, false);
// ////////// 2D時間セットボタン1 ///

// /// 2D時間セットボタン2 //////////
// var yearset2 = this.props.year2+"-11-30";
// var button2Dtimeset2 = document.getElementById('2Dtimeset2');
// button2Dtimeset2.addEventListener('click', function() {
//   view2D.clock.currentTime =  JulianDate.fromIso8601(yearset2);
//   view2D.clock.multiplier = 1;
//   view2D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 2D時間セットボタン2 ///

// /// 2D時間セットボタン3 //////////
// var yearset3 = this.props.year3+"-11-30";
// var button2Dtimeset3 = document.getElementById('2Dtimeset3');
// button2Dtimeset3.addEventListener('click', function() {
//   view2D.clock.currentTime =  JulianDate.fromIso8601(yearset3);
//   view2D.clock.multiplier = 1;
//   view2D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 2D時間セットボタン3 ///

// /// 2D時間セットボタン4 //////////
// var yearset4 = this.props.year4+"-11-30";
// var button2Dtimeset4 = document.getElementById('2Dtimeset4');
// button2Dtimeset4.addEventListener('click', function() {
//   view2D.clock.currentTime =  JulianDate.fromIso8601(yearset4);
//   view2D.clock.multiplier = 1;
//   view2D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 2D時間セットボタン4 ///

// /// 2D時間セットボタン5 //////////
// var yearset5 = this.props.year5+"-11-30";
// var button2Dtimeset5 = document.getElementById('2Dtimeset5');
// button2Dtimeset5.addEventListener('click', function() {
//   view2D.clock.currentTime =  JulianDate.fromIso8601(yearset5);
//   view2D.clock.multiplier = 1;
//   view2D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 2D時間セットボタン5 ///

// /// 3D時間セットボタン1 //////////
// var yearset1 = this.props.year1+"-11-30";
// var button3Dtimeset1 = document.getElementById('3Dtimeset1');
// button3Dtimeset1.addEventListener('click', function() {
//   view3D.clock.currentTime =  JulianDate.fromIso8601(yearset1);
//   view3D.clock.multiplier = 1;
//   view3D.clock.shouldAnimate = true; //if it was paused.
// }, false);
// ////////// 3D時間セットボタン1 ///

// /// 3D時間セットボタン2 //////////
// var yearset2 = this.props.year2+"-11-30";
// var button3Dtimeset2 = document.getElementById('3Dtimeset2');
// button3Dtimeset2.addEventListener('click', function() {
//   view3D.clock.currentTime =  JulianDate.fromIso8601(yearset2);
//   view3D.clock.multiplier = 1;
//   view3D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 3D時間セットボタン2 ///

// /// 3D時間セットボタン3 //////////
// var yearset3 = this.props.year3+"-11-30";
// var button3Dtimeset3 = document.getElementById('3Dtimeset3');
// button3Dtimeset3.addEventListener('click', function() {
//   view3D.clock.currentTime =  JulianDate.fromIso8601(yearset3);
//   view3D.clock.multiplier = 1;
//   view3D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 3D時間セットボタン3 ///

// /// 3D時間セットボタン4 //////////
// var yearset4 = this.props.year4+"-11-30";
// var button3Dtimeset4 = document.getElementById('3Dtimeset4');
// button3Dtimeset4.addEventListener('click', function() {
//   view3D.clock.currentTime =  JulianDate.fromIso8601(yearset4);
//   view3D.clock.multiplier = 1;
//   view3D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 3D時間セットボタン4 ///

// /// 3D時間セットボタン5 //////////
// var yearset5 = this.props.year5+"-11-30";
// var button3Dtimeset5 = document.getElementById('3Dtimeset5');
// button3Dtimeset5.addEventListener('click', function() {
//   view3D.clock.currentTime =  JulianDate.fromIso8601(yearset5);
//   view3D.clock.multiplier = 1;
//   view3D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 3D時間セットボタン5 ///

// /// 時間セットボタンoff //////////
// var buttontimeset = document.getElementById('2Dtimeoff');
// buttontimeset.addEventListener('click', function() {
//   view2D.clock.currentTime =  JulianDate.fromIso8601('2022-08-30');
//   view2D.clock.multiplier = 1;
//   view2D.clock.shouldAnimate = true //if it was paused.
// }, false);
// ////////// 時間セットボタンoff ///





/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(view3D, navoptions);
///// Navigation ///








/// KML生成準備 ////////////

var kmlpre = [];
var kml1_colora = this.props.kml1_color;
var kml2_colora = this.props.kml2_color;
var kml3_colora = this.props.kml3_color;
var kml4_colora = this.props.kml4_color;

var kml1_color = kml1_colora.slice(5,7)+kml1_colora.slice(3,5)+kml1_colora.slice(1,3);
var kml2_color = kml2_colora.slice(5,7)+kml2_colora.slice(3,5)+kml2_colora.slice(1,3);
var kml3_color = kml3_colora.slice(5,7)+kml3_colora.slice(3,5)+kml3_colora.slice(1,3);
var kml4_color = kml4_colora.slice(5,7)+kml4_colora.slice(3,5)+kml4_colora.slice(1,3);

var latmax = 0;
var latmin = 200;
var lonmax = 0;
var lonmin = 200;


  
/// /// １.2列目の閾値で色分け /////////////////////////
// function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
  function mesh3rd2kml1( meshcode3rd , h, c){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    var r = parseInt(strMeshcoe.slice(6,7));
    var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12 + r / 120;
    var lon = u + 100 + v * 0.125 + w * 0.0125;
    var lon2 = lon+0.0125;
    var lat2 = lat+0.008333333;
    var latc = (lat+lat2)/2;
    var lonc = (lon+lon2)/2;
    var hdis = h*ratio+5;

if(latmax<lat){latmax = lat;}
if(lonmax<lon){lonmax = lon;}
if(latmin>lat){latmin = lat;}
if(lonmin>lon){lonmin = lon;}

      var colorkml1;
      if (h >= shikii1) {
        colorkml1 = "1";
        // color1 = "#67ADDF";
      } else if (h >= shikii2) {
        colorkml1 = "2";
      } else if (h >= shikii3) {
        colorkml1 = "3";
      } else {
        colorkml1 = "4";
      }

      var ans_kml = `
      <Placemark>
      <name>メッシュ番号：`+strMeshcoe+`</name>
      <description>高さの値：`+h+`</description>
      <styleUrl>#PolyCol`+colorkml1+`</styleUrl>
      <Polygon>
      <altitudeMode>relativeToGround</altitudeMode>
      <extrude>1</extrude>
      <outerBoundaryIs>
      <LinearRing>
      <coordinates>
      `
      +lon+","+lat+","+hdis+`
     
      `
      +lon2+","+lat+","+hdis+`
     
      `
      +lon2+","+lat2+","+hdis+`
      
      `
      +lon+","+lat2+","+hdis+`
      
      `
      +lon+","+lat+","+hdis
      +
      
      `
      </coordinates>
      </LinearRing>
      </outerBoundaryIs>
      </Polygon>
<LookAt>
      <latitude>`+latc+`</latitude>
      <longitude>`+lonc+`</longitude>
      <altitude>0</altitude>
      <range>1000</range>
      <tilt>0</tilt>
      <heading>0</heading>
      <altitudeMode>relativeToGround</altitudeMode>
    </LookAt>
      </Placemark>
      `;

      return ans_kml ;
  }
  /// ///////////////////////// １.2列目の閾値で色分け ///
  /// /// 2.3列目の値で色分け /////////////////////////
  // function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
    function mesh3rd2kml2( meshcode3rd , h, c){
      var strMeshcoe = String(meshcode3rd);
      var p = parseInt(strMeshcoe.slice(0,2));
      var u = parseInt(strMeshcoe.slice(2,4));
      var q = parseInt(strMeshcoe.slice(4,5));
      var v = parseInt(strMeshcoe.slice(5,6));
      var r = parseInt(strMeshcoe.slice(6,7));
      var w = parseInt(strMeshcoe.slice(7,8));
      var lat = p / 1.5 + q / 12 + r / 120;
      var lon = u + 100 + v * 0.125 + w * 0.0125;
      var lon2 = lon+0.0125;
      var lat2 = lat+0.008333333;
      var latc = (lat+lat2)/2;
      var lonc = (lon+lon2)/2;
        var hdis = h*ratio+5;

if(latmax<lat){latmax = lat;}
if(lonmax<lon){lonmax = lon;}
if(latmin>lat){latmin = lat;}
if(lonmin>lon){lonmin = lon;}

        var colorkml1;
        if (c == 3) {
          colorkml1 = "1";
          // color1 = "#67ADDF";
        } else if (c == 2) {
          colorkml1 = "2";
        } else if (c == 1) {
          colorkml1 = "3";
        } else {
          colorkml1 = "4";
        }
    

        var ans_kml = `
        <Placemark>
        <name>メッシュ番号：`+strMeshcoe+`</name>
        <description>高さの値：`+h+`</description>
          <styleUrl>#PolyCol`+colorkml1+`</styleUrl>
        <Polygon>
        <altitudeMode>relativeToGround</altitudeMode>
        <extrude>1</extrude>
        <outerBoundaryIs>
        <LinearRing>
        <coordinates>
        `
        +lon+","+lat+","+hdis+`
       
        `
        +lon2+","+lat+","+hdis+`
       
        `
        +lon2+","+lat2+","+hdis+`
        
        `
        +lon+","+lat2+","+hdis+`
        
        `
        +lon+","+lat+","+hdis
        +
        
        `
        </coordinates>
          </LinearRing>
        </outerBoundaryIs>
        </Polygon>
<LookAt>
      <latitude>`+latc+`</latitude>
      <longitude>`+lonc+`</longitude>
      <altitude>0</altitude>
      <range>1000</range>
      <tilt>0</tilt>
      <heading>0</heading>
      <altitudeMode>relativeToGround</altitudeMode>
    </LookAt>
          
        </Placemark>
        `;
  
        return ans_kml ;
    }
  
  /// ///////////////////////// 2.3列目の値で色分け ///
  
  /// /// 3.2列目の閾値で色分け /////////////////////////
  // function getJapan3rdmeshRectangle( meshcode3rd , h, color_gbraaa , description_text){
    function mesh3rd2kml3( meshcode3rd , h, c){
      var strMeshcoe = String(meshcode3rd);
      var p = parseInt(strMeshcoe.slice(0,2));
      var u = parseInt(strMeshcoe.slice(2,4));
      var q = parseInt(strMeshcoe.slice(4,5));
      var v = parseInt(strMeshcoe.slice(5,6));
      var r = parseInt(strMeshcoe.slice(6,7));
      var w = parseInt(strMeshcoe.slice(7,8));
      var lat = p / 1.5 + q / 12 + r / 120;
      var lon = u + 100 + v * 0.125 + w * 0.0125;
      var lon2 = lon+0.0125;
      var lat2 = lat+0.008333333;
      var latc = (lat+lat2)/2;
      var lonc = (lon+lon2)/2;
        var hdis = h*ratio+5;

if(latmax<lat){latmax = lat;}
if(lonmax<lon){lonmax = lon;}
if(latmin>lat){latmin = lat;}
if(lonmin>lon){lonmin = lon;}
  
        var colorkml1;
        if (c >= shikii1) {
          colorkml1 = "1";
          // color1 = "#67ADDF";
        } else if (c >= shikii2) {
          colorkml1 = "2";
        } else if (c >= shikii3) {
          colorkml1 = "3";
        } else {
          colorkml1 = "4";
        }
    
      var ans_csv = {
              // parent: data1995,
              // id:meshcode3rd ,
              // description : "<p>"+description_text+"</p>",
              // rectangle : {
                  coordinates : Rectangle.fromDegrees( lon , lat , lon+0.0125 , lat+0.008333333 ),
                  height : 0,
                  extrudedHeight : h*ratio,
                  // material : Color.fromRgba( color_gbra )
                  outline : true,
                  outlineColor : "white",
                  outlineWidth : 1,
                  // shadows : ShadowMode.ENABLED
              // }
              
          };

      var ans_kml = `
      <Placemark>
      <name>メッシュ番号：`+strMeshcoe+`</name>
      <description>高さの値：`+h+`</description>
      <styleUrl>#PolyCol`+colorkml1+`</styleUrl>
      <Polygon>
      <altitudeMode>relativeToGround</altitudeMode>
      <extrude>1</extrude>
      <outerBoundaryIs>
      <LinearRing>
      <coordinates>
      `
      +lon+","+lat+","+hdis+`
     
      `
      +lon2+","+lat+","+hdis+`
     
      `
      +lon2+","+lat2+","+hdis+`
      
      `
      +lon+","+lat2+","+hdis+`
      
      `
      +lon+","+lat+","+hdis
      +
      
      `
      </coordinates>
      </LinearRing>
      </outerBoundaryIs>
      </Polygon>
<LookAt>
      <latitude>`+latc+`</latitude>
      <longitude>`+lonc+`</longitude>
      <altitude>0</altitude>
      <range>1000</range>
      <tilt>0</tilt>
      <heading>0</heading>
      <altitudeMode>relativeToGround</altitudeMode>
    </LookAt>

      </Placemark>
      `;

      return ans_kml ;
    }
    /// ///////////////////////// 3.2列目の閾値で色分け ///
  
  
  function getkml(no,year,dataname) {
    var kmlprepre=[];
    var req = new XMLHttpRequest();
    req.open('get', csv+"_"+year+".csv", true);
    req.send(null);
    req.onload = function() {
      // setCSV(req.responseText);
      //ここでlocalStorageへ
      var dataArr;
      // var r = document.getElementById('r');
      var tmp = req.responseText.split('\n');
      // console.log(tmp.length);
  
      // var firstview = viewer.entities.add(mesh3rdRect1(tmp[0].substr(0,6),tmp[0].substr(7))).then(
      //   viewer.flyTo(viewer.entities)
      // )
      // .catch(failureCallback);

      if (csvmode == "color_shikii") {
  
      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          // viewer.entities.add(
          //   {
          //     parent: dataname,
          //     // id:meshcode3rd ,
          //     // description : "<p>"+description_text+"</p>",
          //     rectangle: mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2])
          //   }
          kmlprepre[i] = mesh3rd2kml3(dataArr[0],dataArr[1],dataArr[2])




          // );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }

    } else if(shikii1 !== 0){
  
        for(let i = 0, len = tmp.length; i< len ; i++){
          dataArr = tmp[i].split(',');
          var minmin = Number(min);
          if (dataArr[1]>minmin) {

            kmlprepre[i] = mesh3rd2kml1(dataArr[0],dataArr[1],dataArr[2])
  
            // viewer.entities.add(
            //   {
            //     parent: dataname,
            //     // id:meshcode3rd ,
            //     // description : "<p>"+description_text+"</p>",
            //     rectangle: mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2])
            //   }
            // );
          // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
          // if(tmp[0]){
          // }
          }
        }
    
  
  
    } else {
  
      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          kmlprepre[i] = mesh3rd2kml2(dataArr[0],dataArr[1],dataArr[2])

          // viewer.entities.add(
          //   {
          //     parent: dataname,
          //     // id:meshcode3rd ,
          //     // description : "<p>"+description_text+"</p>",
          //     rectangle: mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2])
          //   }
          // );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
    };
    
  };
  return kmlprepre;

  };

  var data1995kml = getkml(this.props.no,this.props.year1, data1995);
  var data2000kml = getkml(this.props.no,this.props.year2, data2000);
  var data2005kml = getkml(this.props.no,this.props.year3, data2005);
  var data2010kml = getkml(this.props.no,this.props.year4, data2010);
  var data2015kml = getkml(this.props.no,this.props.year5, data2015);

  var year1kml = this.props.year1;
  var year2kml = this.props.year2;
  var year3kml = this.props.year3;
  var year4kml = this.props.year4;
  var year5kml = this.props.year5;
  var year5_6kml = Number(year5kml) - Number(2005);
  var year6kml = Number(year5kml) + year5_6kml;
  var year7kml = Number(year6kml) + (Number(year5kml) - Number(year1kml))/4;

   var year1_b = year1kml+'-04-01';
  var year2_b = year2kml+'-04-01';
  var year3_b = year3kml+'-04-01';
  var year4_b = year4kml+'-04-01';
  var year5_b = year5kml+'-04-01';
  
  var year1_e = year2kml+'-04-01';
  var year2_e = year3kml+'-04-01';
  var year3_e = year4kml+'-04-01';
  var year4_e = year5kml+'-04-01';
  var year5_e = year6kml+'-04-01';
  
 if(year1kml>0){ var year1_begin = year1_b } else {  var year1_begin = year5_b };
  if(year2kml>0){ var year2_begin = year2_b } else {  var year2_begin = year5_b };
  if(year3kml>0){ var year3_begin = year3_b } else {  var year3_begin = year5_b };
  if(year4kml>0){ var year4_begin = year4_b } else {  var year4_begin = year5_b };
  if(year5kml>0){ var year5_begin = year5_b } else {  var year5_begin = year5_b };

  if(year2kml>0){ var year1_end = year1_e } else { var year1_end = year5_begin };
  if(year3kml>0){ var year2_end = year2_e } else { var year2_end = year5_begin };
  if(year4kml>0){ var year3_end = year3_e } else { var year3_end = year5_begin };
  if(year5kml>0){ var year4_end = year4_e } else { var year4_end = year5_begin };
  if(year6kml>0){ var year5_end = year5_e } else { var year5_end = year5_begin };

  var no = this.props.no;
  var title = this.props.title;
  var cityname = this.props.cityname;


////////////// KML生成準備 ///
/// KML生成 /////
  var kmlfilename = (cityname+"_"+title+".kml");



  const button1 = document.getElementById('kmldownloadbutton2');
  button1.addEventListener('click', function (evt) {
  evt.preventDefault();

  var latcenter = (latmax+latmin)/2;
  var loncenter = (lonmax+lonmin)/2;
  var lonheightratio = 170000*(lonmax-lonmin);

  // var kmlll = "Hello"+", KML! KML!"+min+ratio;
  var kmlll = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>



  
  <name>`+cityname+"_"+title+`</name>
  
  <Style id="PolyCol1">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml1_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <Style id="PolyCol2">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml2_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <Style id="PolyCol3">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml3_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <Style id="PolyCol4">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml4_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <LookAt>
  <longitude>`+loncenter+`</longitude>
  <latitude>`+latcenter+`</latitude>
  <altitude>0</altitude>
  <range>`+lonheightratio+`</range>
  <tilt>30</tilt>
  <heading>-25</heading>
  <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>

  <Folder>
  <name>`+year1kml+`</name>
  <TimeSpan>
  <begin>`+year1_begin+`</begin>
  <end>`+year1_end+`</end>

  </TimeSpan>

  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei/`+no+`_`+year1kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data1995kml+`

   </Folder>


     <Folder>
  <name>`+year2kml+`</name>
  <TimeSpan>
  <begin>`+year2_begin+`</begin>
  <end>`+year2_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei/`+no+`_`+year2kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2000kml+`
  </Folder>
  <Folder>
  <name>`+year3kml+`</name>
  <TimeSpan>
  <begin>`+year3_begin+`</begin>
  <end>`+year3_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei/`+no+`_`+year3kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2005kml+`
  </Folder>
  <Folder>
  <name>`+year4kml+`</name>
  <TimeSpan>
  <begin>`+year4_begin+`</begin>
  <end>`+year4_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei/`+no+`_`+year4kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2010kml+`
  </Folder>

  <Folder>
  <name>`+year5kml+`</name>
  <TimeSpan>
  <begin>`+year5_begin+`</begin>
  <end>`+year5_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei/`+no+`_`+year5kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2015kml+`
  </Folder>





  </Document>
  </kml>
  `;

  const blob = new Blob([kmlll], {type: 'application/vnd.google-earth.kml+xml kml'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = kmlfilename;
  a.href = url;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
}


  // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));

          //   let currentvalue = document.getElementById('rotate').value;
          //   if (currentvalue == "回転させる") {
          //       document.getElementById("rotate").value="Stop"
          //       viewer.clock.onTick.addEventListener(function(clock) {
          //       viewer.scene.camera.rotateRight(-0.005);
          //       });
          //   };
          //   viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));

          // }, false);
///// KML生成 ///




  )












  }
 
  // componentWillUnmount() {
  //   this.view3D.destroy();
  // }







}

export default CesiumContainer

